import { Controller } from 'stimulus';
import Cleave from 'cleave.js';
import currency from 'currency.js';
import { DirectUpload } from '@rails/activestorage';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = [
    'investorSection', 'beneficialSection', 'uploads', 'trueFalseSelect',
    'investmentAmountInput', 'calculatedPayment', 'investmentAmountError',
    'fileInput', 'submitButton', 'addAdmin', 'signatorySection', 'form',
    'contact', 'emptyContact', 'contactsList', 'addContactButton',
    'investorProceeds', 'investorStatusSection', 'submitSection',
    'signatoryEmail', 'erisaOption'
  ]

  connect() {
    $('.collapse').on('show.bs.collapse', (e) => {
      const $this = $(e.target);
      const sectionIncomplete = $this.closest('.bc-form-section').hasClass('incomplete');

      $this.find('[data-field]').each((i, el) => {
        const $el = $(el);
        $el.removeClass('error-field');
        if (sectionIncomplete) {
          $el.data('field').split(' ').forEach((k) => {
            if (this.errorFields.includes(k)) {
              $el.addClass('error-field');
            }
          });
        }
      });

      $('html').animate({
        scrollTop: $this.closest('.accordion').offset().top + $this.closest('.bc-form-section').index() * 59 + 1
      }, 250);
    });

    this.$el = $(this.element);
    this.$form = $(this.formTarget);
    this.debounce = null;
    this.formErrors = null;
    this.errorFields = this.$el.data('errors');

    this.constructor.targets.forEach(target => {
      if (this['has' + target.charAt(0).toUpperCase() + target.slice(1) + 'Target']) {
        this[`$${target}`] = $(this[`${target}Target`]);
      }
    });

    this.$form.on('change', 'input[type=text], input[type=email]:not(#admin_email), input[type=number], input[type=radio], input[type=checkbox], input[type=tel], select', this.onChange.bind(this));

    this.$form.on('keyup', 'input[type=email]', (e) => {
      $(e.target).removeClass('is-invalid');
    });

    this.$form.on('submit', () => {
      this.prepareForSave();
      return true;
    });

    this.fileInputTargets.forEach((fileInput) => {
      const $fileInput = $(fileInput);

      $fileInput.find('input[type=file]').on('change', (e) => {
        const input = event.target;
        const file = event.target.files[0];
        const url = input.dataset.directUploadUrl;
        const upload = new DirectUpload(file, url);

        upload.create((error, blob) => {
          if (error) {

          } else {
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("value", blob.signed_id);
            hiddenField.name = input.name
            this.element.appendChild(hiddenField)
            $fileInput.find('a').attr('href', `/rails/active_storage/blobs/redirect/${blob.signed_id}/${blob.filename}`).html(blob.filename).show();
            $fileInput.find('.btn').html('Change File');
            this.autosave();
          }
        });
      });
    });

    new Cleave(this.investmentAmountInputTarget, {
      prefix: '$',
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      numeralDecimalScale: 0
    });

    $("div[id^='tooltip-template-'").each((i, e) => {
      const $e = $(e);
      tippy(`.tooltip-${$e.attr('id').slice(17)}`, {
        content: $e.html(),
        allowHTML: true
      });
    });

    this.updateState();
    //this.autosave();
  }

  prepareForSave() {
    for (const [i, v] of this.contactTargets.entries()) {
      const fieldPrefix = `submission[contacts_attributes[${i}]`
      $(v).find('input,select').each((i, el) => {
        const $el = $(el);
        $el.attr('name', $el.attr('name').replace(/submission\[contacts_attributes\]\[\d?\]/, fieldPrefix));
      });
    }

    const values = $(this.beneficialSectionTargets).find('table input:not(:disabled)').map(function() { return $(this).val() }).get();
    const data = [];
    for (let i = 0; i < values.length; i += 5) {
      data.push([values[i], values[i+1], values[i+2], values[i+3], values[i+4]]);
    }
    this.$form.find('input[name="submission[attachment_x]"]').val(JSON.stringify(data));
  }

  autosave() {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.prepareForSave();
      this.$form.ajaxSubmit({
        url: this.$el.data('url'),
        success: (data) => {
          this.formErrors = data.errors;
          this.errorFields = data.error_fields;

          if (this.formErrors.length === 0) {
            $(this.submitButtonTargets).prop('disabled', false);
            if (this.$submitSection) {
              this.$submitSection.removeClass('inactive');
            }
          } else {
            if (this.$submitSection) {
              this.$submitSection.addClass('inactive');
            }
          }

          this.$el.find('.bc-form-section').each((i, e) => {
            if (i === 0 || i > 4) {
              return;
            }
            const $e = $(e);
            $e.removeClass('complete incomplete unstarted');
            $e.addClass(data.sections[i-1][0]);
            $e.find('.bc-form-complete-status').text(data.sections[i-1][1]);
            $e.find('.bc-form-updated-date').text(data.sections[i-1][2]);
          });
        }
      });
    }, 50);
  }

  onFormCheck(e) {
    e.preventDefault();
    if (this.formErrors) {
      alert(this.formErrors.join("\n"));
    }
  }

  onNext(e) {
    $(e.target).closest('.bc-form-section').next().find('.collapse').collapse('show');
  }

  updateAddContactState() {
    $(this.addContactButtonTarget).toggle($(this.contactsListTarget).children().length < 2);
  }

  onAddOwnerRow(e) {
    this.addOwnerRow($(e.target).prev().find('tbody'));
  }

  onAddContact(e) {
    const $newEntry = $('<div class="bc-form-contact" data-submission-form-target="contact"></div>');
    $newEntry.append($(this.emptyContactTarget).html());
    $(this.contactsListTarget).append($newEntry);
    this.updateAddContactState();

  }

  onRemoveContact(e) {
    $(e.target).parent().remove();
    this.updateAddContactState();
    this.autosave();
  }

  onAddAdmin(e) {
    e.preventDefault();
    const $button = $(e.currentTarget);
    const $input = this.$addAdmin.find('input');
    const newAdmin = $input.val();
    if (newAdmin !== undefined && newAdmin !== '') {
      $button.prop('disabled', true);
      $.post({
        url: $button.data('url'),
        data: { email: newAdmin },
        success: (response) => {
          this.$addAdmin.html(response);
        },
        complete: () => {
          $button.prop('disabled', false);
          $input.val('');
        }
      });
    }
  }

  onRemoveAdmin(e) {
    const $link = $(e.currentTarget);
    const email = $link.data('email');
    if (confirm(`Remove ${email} as an admin for this application?`)) {
      $.ajax({
        url: $link.data('url'),
        data: { email: email },
        method: 'DELETE'
      });
      $link.closest('tr').remove();
    }
  }

  onChange(e) {
    const $changedElement = $(e.currentTarget);

    // uncheck sub-radios on ubti change
    if ($changedElement.attr('name') === 'submission[ubti]') {
      this.$form.find("[name='submission[ubti_sub]']").prop('checked', false);
    }

    // toggle linked checkboxes (with unique "none of the above" values)
    if ($changedElement.is("input") && $changedElement.attr('type') == 'checkbox' && $changedElement.is(':checked')) {
      const $formCheck = $changedElement.closest('.form-check');
      if ($formCheck.length) {
        if ($formCheck.data('check') === 'unique') {
          $formCheck.siblings('.form-check').find('input').prop('checked', false);
        } else if ($formCheck.data('check') !== 'unique') {
          $formCheck.siblings('.form-check[data-check=unique]').find('input').prop('checked', false);
        }
      }
    }

    $(this.submitButtonTargets).prop('disabled', true);

    this.updateState();
    this.autosave();
  }

  addOwnerRow($tbody) {
    $tbody.append('<tr><td><input type="text" class="form-control"/></td><td><input type="text" class="form-control"/></td><td><input type="text" class="form-control"/></td><td><input type="text" class="form-control"/></td><td><input type="text" class="form-control"/></td></tr>');
  }

  updateState() {
    const investmentAmount = this.$form.find('input[name="submission[investment_amount]"]').val();
    const investorName = this.$form.find('input[name="submission[investor_name]"]').val();

    const investorType = this.$form.find('input[name="submission[investor_type]"]:checked').val();
    const accreditationValue = this.$form.find('input[name="submission[investor_accreditation]"]:checked').val();

    this.$investmentAmountError.hide();

    if (this.$investmentAmountInput.val() === '$') {
      $(this.calculatedPaymentTargets).html('');
      $(this.calculatedPaymentTargets).html('');
    } else {
      const investmentAmount = currency(this.$investmentAmountInput.val());
      $(this.calculatedPaymentTargets).html(currency(investmentAmount / 4).format().replace('.00', ''));
      if (investmentAmount < 500000) {
        this.$investmentAmountError.show();
      }
    }

    $(this.investorSectionTargets).hide().find('input, select').prop('disabled', true);
    $(this.signatorySectionTargets).hide().find('input, select').prop('disabled', true);

    $(this.erisaOptionTargets).each((i, e) => {
      const $e = $(e);
      $e.closest('.form-group').find('> .bc-js-dependent').toggle($e.is(':checked'));
    });

    $(this.trueFalseSelectTargets).each((i, e) => {
      const $dependents = $(e).closest('.row').find('> * > .bc-js-dependent, > .bc-js-dependent');
      const val = $(e).val();
      $dependents.hide();
      if (val && val.length) {
        $dependents.filter(`.${$(e).val()}`).show();
      }
    });

    this.investorProceedsTargets.forEach((row) => {
      const $row = $(row);
      let showInvestorProceeds = false;
      $row.find('> div > .form-check [value=true]').each((i, e) => {
        if ($(e).is(':checked')) {
          showInvestorProceeds = true;
        }
      });
      $row.find('> .bc-js-dependent').toggle(showInvestorProceeds);
    });

    this.$form.find('input[type=email]').each((i, e) => {
      const $input = $(e);
      const value = $input.val();
      $input.toggleClass('is-invalid', typeof(value) === 'string' && value !== '' && value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null);
    });

    if (investorType !== undefined) {
      $(this.investorSectionTargets).filter(`[data-for=${investorType}]`).show().find('input, select').prop('disabled', false);

      if (investorType == 'individual') {
        $(this.signatorySectionTargets).filter(`[data-for=individual]`).show().find('input, select').prop('disabled', false);
      } else {
        $(this.signatorySectionTargets).filter(`[data-for=other]`).show().find('input, select').prop('disabled', false);
      }

      let uploadLabel = null;
      if (investorType == 'individual' || investorType == 'ira') {
        uploadLabel = 'your';
      } else if (investorType == 'trust') {
        uploadLabel = 'Trustee';
      } else if (investorType == 'firm_fund') {
        uploadLabel = 'Majority Beneficial Owner(s)'
      }

      this.$uploads.toggle(uploadLabel !== null);

      $(this.beneficialSectionTargets).hide();

      const $activeStatusInputs = this.$form.find("[name='submission[investor_status][]']:checked").filter((i, e) => {
        return $(e).attr('value') !== 'no_investor_status';
      });

      $activeStatusInputs.closest('.form-group').find('> .form-group').show();

      $(this.beneficialSectionTargets).each((i, e) => {
        let minOwners = 0;
        let $e = $(e);
        $e.find('[type=number]').each((i, e) => {
          const num = parseInt($(e).val());
          if (!isNaN(num)) {
            minOwners += num;
          }
        })
        let rowsToAdd = minOwners - $e.find('tbody tr').length;
        if (rowsToAdd > 0) {
          for( ; rowsToAdd > 0; rowsToAdd--) {
            this.addOwnerRow($e.find('tbody'));
          }
        } else if (rowsToAdd < 0) {
          $e.find('tbody tr').each((i, tr) => {
            const $tr = $(tr);
            let valueFound = false;
            $tr.find('input').each((i, input) => {
              const val = $(input).val();
              if (val && val.length) {
                valueFound = true;
                return false;
              }
            });
            if (!valueFound) {
              $tr.remove();
              rowsToAdd += 1;
              if (rowsToAdd === 0) {
                return false;
              }
            }
          });
        }
      });

      $(this.investorStatusSectionTargets).hide();

      const $activePurchaserInputs = this.$form.find("[name='submission[qualified_purchaser][]']:checked").filter((i, e) => {
        return $(e).attr('value') !== 'other_qualified';
      });

      $activePurchaserInputs.closest('.form-group').next().show();


    }
  }
}
