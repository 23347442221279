import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.updateState();
  }

  onChange() {
    $(this.element).find('[type=submit]').prop('disabled', !$(this.element).find('#user_terms_of_service').is(':checked'))
  }
}
