import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'openTable' ]

  connect() {
    this.table = $(this.openTableTarget).DataTable({
      lengthChange: false,
      pageLength: 25,
      searching: false,
      serverSide: true,
      ordering: false,
      scrollX: true,
      ajax: {
        url: this.element.dataset.url
      },
      columns: [
        { title: 'Activity Date*', data: 'activity_date' },
        { title: 'Status', data: 'status' },
        { title: 'Signatory Name', data: 'signatory_name' },
        { title: 'Signatory Email', data: 'signatory_email' },
        { title: 'Entity Name', data: 'entity_name' },
        { title: 'Total Committed Amount', data: 'amount' },
        { title: 'Entity Type', data: 'entity_type' },
        { title: 'ACCREDITED INVESTOR STATUS', data: 'accredited' },
        { title: 'QUALIFIED PURCHASER STATUS', data: 'qualified_purchaser' },
        { title: 'QUALIFIED CLIENT STATUS', data: 'qualified_client' },
        { title: 'Edit Notes', data: 'edit_notes', width: 300 },
        { title: 'Feedback', data: 'feedback', width: 300 },
      ]
    });

    $(this.element).on('click', 'td', (e) => {
      const data = this.table.row($(e.target).closest('tr')[0]).data();
      window.location = data.url;
    });
  }
}
